import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AboutUsPage } from "../../../constants/index"
import * as styles from "./styles.module.scss"

const AboutDetailPage = () => {
	const aboutUsDetails = AboutUsPage.aboutUsDetails
	const aboutSoftware = AboutUsPage.aboutSoftware

	return (
		<>
			<div className={`mt-5  ${styles.AboutUsmain}`}>
				<Container className={styles.aboutUSContainer}>
					<Row>
						{aboutUsDetails.map((about, index) => {
							return (
								<Col key={index} sm={12}>
									<h1 className={`text-start ${styles.aboutUsHeading0}`}>{about.SoftwareHeading}</h1>
									<p className={`text-start ${styles.aboutUsParagraph}`}>{about.softwareDetail}</p>
									<h1 className={`text-start ${styles.aboutUsHeading}`}>{about.Innovation}</h1>
									<p className={`text-start ${styles.aboutUsParagraph}`}>{about.InnovationDetail}</p>
									<h1 className={`text-start ${styles.aboutUsHeading}`}>{about.Expertise}</h1>
									<p className={`text-start ${styles.aboutUsParagraph}`}>{about.ExpertiseDetail}</p>
								</Col>
							)
						})}
					</Row>
				</Container>
				<Container className={styles.aboutUSContainer}>
					<Row>
						{aboutSoftware.map((about, index) => {
							return (
								<Col key={index} sm={12}>
									<h1 className={`text-start ${styles.aboutUsHeading1}`}>{about.SoftwareEngineering}</h1>
									<p className={`text-start ${styles.aboutUsParagraph}`}>{about.SoftwareEngDetail}</p>
									<h1 className={`text-start ${styles.aboutUsHeading}`}>{about.EnterpriseMobility}</h1>
									<p className={`pb-3 text-start ${styles.aboutUsParagraph}`}>{about.EnterpriseDetail}</p>
								</Col>
							)
						})}
					</Row>
				</Container>
			</div>
		</>
	)
}
export default AboutDetailPage
