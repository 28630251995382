import Footer from "components/shared/Footer"
import HeaderTwo from "components/shared/HeaderTwo"
import React from "react"
import { Helmet } from "react-helmet"
import AboutDetailPage from "components/pages/AboutUs"

const AboutPage = () => {
	return (
		<>
			<Helmet>
				<title>Refstal Solutions | AboutUs </title>
			</Helmet>
			<HeaderTwo />
			<AboutDetailPage />
			<Footer />
		</>
	)
}

export default AboutPage
